import { useContext, useState } from "react";
import {
  UICard,
  UIMultiRow,
  UIButton,
  UIPopupContainer,
  UIActionRowsCustomCard,
  UISuccessButton,
  UIMultiPasswordRow,
} from "../../components/UI";
import { UserContext } from '../../utils/UserContext'

import MainContainer from "../../components/MainContainer";
import { useNavigate } from "react-router-dom";
import axios from 'axios'

import module from "./style.module.scss";

function Settings() {
  const [routing, setRouting] = useState("");
  const [successTitle, setSuccessTitle] = useState("");
  const { state, setState } = useContext(UserContext);
  const navigate = useNavigate();

  const checkRouting = () => {
    switch (routing) {
      case "success":
        return <PageSuccessCard />;
      case "wallet-address":
        return <ChangeWalletAddress />;
      case "password":
        return <ChangePassword />;
      case "email":
        return <ChangeEmail />;
      case "set-telegram":
        return <SetTelegram />;
      case "change-telegram":
        return <ChangeTelegram />;
      default:
        return <></>;
    }
  };

  const pageData = [
    {
      title: "Personal details",
      customClassName: module.settings__card_inputs,

      items: [
        {
          paragraphText: "Wallet Address",
          inputText: state?.user?.address || 'Address not Present',
          buttonText: "Change Wallet Address",
          type: "text",
          copyOnMobile: true,
          routingFunc: () => setRouting("wallet-address"),
        },

        {
          paragraphText: "Password",
          inputText: "some-password",
          buttonText: "Change Password",
          type: "password",
          routingFunc: () => setRouting("password"),
        },
      ],
    },
    {
      title: "Contact information",
      items: [
        {
          paragraphText: "Email Address",
          inputText: state?.user?.email || 'user@email.com',
          buttonText: "Change Email",
          type: "text",
          routingFunc: () => setRouting("email"),
        },
        {
          paragraphText: "Telegram Username",
          inputText: '',
          buttonText: `${ state?.user?.telegram ? 'Change' : 'Set'} Telegram Username`,
          type: "text",
          placeholder: state?.user?.telegram || "@username",
          routingFunc: () => setRouting(`${ state?.user?.telegram ? 'change' : 'set' }-telegram`),
        },
      ],
    },
  ];

  const walletAddress = [
    {
      paragraphText: "Old Wallet Address",
      value: state?.user?.address || 'address not present',
      readOnly: false,
      registerParams: {},
    },
    {
      paragraphText: "New Wallet Address",
      readOnly: false,
      placeholder: "0x3f9...",
      registerName: "wallet",
      registerParams: {
        minLength: {
          value: 8,
          message:
            "For ERC20 address type only. Please check the withdrawal address length and character content and try again. ",
        },
      },
    },
  ];

  const changeEmail = [
    {
      paragraphText: "Old Email",
      readOnly: true,
      value: state?.user?.email,
      type: "email",
    },
    {
      paragraphText: "New Email",
      placeholder: "Enter your email",
      type: "email",
      registerName: "newEmail",
      registerParams: {
        pattern: {
          message:
            "Please enter your email address in format: yourname@example.com",
          value: /[a-z0-9]+@[a-z]+.+[a-z]{2,4}/,
        },
      },
    },
  ];

  const setTelegram = [
    {
      paragraphText: "Telegram Username",
      registerName: "telegramUsername",
      placeholder: "@username",
      registerParams: {
        pattern: {
          value: /^@/,
          message: "Please enter your telegram username in format: @username",
        },
      },
    },
  ];

  const changeTelegram = [
    {
      paragraphText: "Old Telegram Username",
      placeholder: "@username",
      value: state?.user?.telegram,
    },
    {
      paragraphText: "New Telegram Username",
      registerName: "telegramNewUsername",
      placeholder: "@username",
      registerParams: {
        pattern: {
          value: /^@/,
          message: "Please enter your telegram username in format: @username",
        },
      },
    },
  ];

  const PageSuccessCard = () => {
    return (
      <UISuccessButton
        title={
          successTitle === "wallet-address"
            ? "Change Wallet Address"
            : successTitle === "password"
            ? "Change the Password"
            : successTitle === "email"
            ? "Change Email"
            : successTitle === "set-telegram"
            ? "Set Telegram Username"
            : successTitle === "change-telegram"
            ? "Change Telegram Username"
            : "Process confirmed"
        }
        onClick={() => {
          setSuccessTitle("");
          return setRouting("");
        }}
      />
    );
  };

  const SettingsButtons = () => (
    <div className={module.settings__buttons}>
      <UIButton
        onClick={(event) => {
          switch (routing) {
            case "success":
              return <PageSuccessCard />;
            case "wallet-address":
              return <ChangeWalletAddress />;
            case "password":
              let validationErrors = Array.from(document.getElementsByClassName('validation-error')).filter((element) => element.textContent !== '')
              let fields = event.target.form.elements
              let currentPassword = fields.password[0].value
              let password = fields.password[1].value
              let passwordConfirmation = fields.passwordMatch.value

              if (validationErrors.length === 0 && currentPassword && password && passwordConfirmation) {
                axios.post(`${process.env.REACT_APP_API_HOST}/users/data/password`, { user: { current_password: currentPassword, password: password, password_confirmation: passwordConfirmation } }, { withCredentials: true })
                  .then(response => {
                    if (response.data.user) {
                      setState(response.data);

                      setSuccessTitle(routing);

                      return setRouting("success");
                    } else if (response.data.error === 'invalid_password') {
                      alert('invalid password')

                      return setRouting("password");
                    } else {
                      setSuccessTitle("");
                      return setRouting("");
                    }
                  })
                  .catch((error) => {
                    setSuccessTitle("");
                    console.log('api errors:', error)
                    return setRouting("");
                  })

              }
              return <ChangePassword />;
            case "email":
              return <ChangeEmail />;
            case "set-telegram":
              return <SetTelegram />;
            case "change-telegram":
              return <ChangeTelegram />;
            default:
              return <></>;
          }
        }}
      >
        Confirm
      </UIButton>
      <UIButton onClick={() => setRouting("")} blueButton={false}>
        Cancel
      </UIButton>
    </div>
  );
  
  const ChangeWalletAddress = () => (
    <UIActionRowsCustomCard
      title="Change Wallet Address"
      cardInputItems={walletAddress}
      formSubmitFunction={async (event) => {
        axios.put(`${process.env.REACT_APP_API_HOST}/users/${state.user.id}`, { user: { address: event.wallet }}, { withCredentials: true })
          .then(response => {
            if (response.data) {

              setState(response.data);

              setSuccessTitle(routing);
              return setRouting("success");
            } else {
              setSuccessTitle("");
              return setRouting("");
            }
          })
          .catch((error) => {
            setSuccessTitle("");
            console.log('api errors:', error)
            return setRouting("");
          })
      }}
      size=""
    >
      <SettingsButtons />
    </UIActionRowsCustomCard>
  );

  const ChangePassword = () => (
    <UICard title="Change the Password">
      <UIMultiPasswordRow
        passwordMatch={false}
        defaultRegisterHookPropsIncluded={false}
        paragraphPasswordText="Your Password"
      />
      <UIMultiPasswordRow paragraphPasswordText="New Password"/>
      <SettingsButtons />
    </UICard>
  );

  const ChangeEmail = () => (
    <UIActionRowsCustomCard
      cardInputItems={changeEmail}
      formSubmitFunction={async (event) => {
        axios.put(`${process.env.REACT_APP_API_HOST}/users/${state.user.id}`, { user: { email: event.newEmail }}, { withCredentials: true })
          .then(response => {
            if (response.data) {

              setState(response.data);

              setSuccessTitle(routing);
              return setRouting("success");
            } else {
              setSuccessTitle("");
              return setRouting("");
            }
          })
          .catch((error) => {
            setSuccessTitle("");
            console.log('api errors:', error)
            return setRouting("");
          })
      }}
      size=""
      title="Change Email"
    >
      <SettingsButtons />
    </UIActionRowsCustomCard>
  );

  const SetTelegram = () => (
    <UIActionRowsCustomCard
      cardInputItems={setTelegram}
      formSubmitFunction={async (event) => {
        axios.put(`${process.env.REACT_APP_API_HOST}/users/${state.user.id}`, { user: { telegram: event.telegramUsername }}, { withCredentials: true })
          .then(response => {
            if (response.data) {

              setState(response.data);

              setSuccessTitle(routing);
              return setRouting("success");
            } else {
              setSuccessTitle("");
              return setRouting("");
            }
          })
          .catch((error) => {
            setSuccessTitle("");
            console.log('api errors:', error)
            return setRouting("");
          })
      }}
      size=""
      title="Set Telegram Username"
    >
      <SettingsButtons />
    </UIActionRowsCustomCard>
  );

  const ChangeTelegram = () => (
    <UIActionRowsCustomCard
      cardInputItems={changeTelegram}
      formSubmitFunction={async (event) => {
        axios.put(`${process.env.REACT_APP_API_HOST}/users/${state.user.id}`, { user: { telegram: event.telegramNewUsername }}, { withCredentials: true })
          .then(response => {
            if (response.data) {

              setState(response.data);

              setSuccessTitle(routing);
              return setRouting("success");
            } else {
              setSuccessTitle("");
              return setRouting("");
            }
          })
          .catch((error) => {
            setSuccessTitle("");
            console.log('api errors:', error)
            return setRouting("");
          })
      }}
      size=""
      title="Change Telegram Username"
    >
      <SettingsButtons />
    </UIActionRowsCustomCard>
  );

  if (state?.logged_in) {
    return (
      <MainContainer className={module.settings}>
        {pageData &&
          pageData.map((b, indexB) => (
            <>
              <UICard
                className={
                  module.settings__card +
                  " " +
                  module.desktop +
                  " " +
                  b.customClassName
                }
                contentClassName={module["settings__card-content"]}
                content
                key={indexB}
                title={b.title}
                size="original"
              >
                {b.items.map((i, index) => (
                  <UIMultiRow
                    paragraphText={i.paragraphText}
                    value={i.inputText}
                    readOnly
                    rowPlacing
                    paragraphSize="large"
                    type={i.type}
                    copyValue={i.inputText}
                    key={index}
                    className={i.customClassName + `${i.paragraphText === 'Wallet Address' && !state?.user?.address ? ' ' + module.placeholder : ''}`}
                    placeholder={i.placeholder}
                  >
                    <UIButton blueButton={false} onClick={i.routingFunc}>
                      {i.buttonText}
                    </UIButton>
                  </UIMultiRow>
                ))}
              </UICard>

              <UICard
                className={
                  module.settings__card +
                  " " +
                  module.mobile +
                  " " +
                  b.customClassName
                }
                contentClassName={module["settings__card-content"]}
                content
                key={indexB}
                title={b.title}
                size="original"
              >
                {b.items.map((i, index) => (
                  <UIMultiRow
                    paragraphText={i.paragraphText}
                    value={
                      i.copyOnMobile !== undefined
                        ? i.inputText.slice(0, 25) + "..."
                        : i.inputText
                    }
                    readOnly
                    rowPlacing
                    paragraphSize="large"
                    type={i.type}
                    copyValue={i.inputText}
                    key={index}
                    className={i.customClassName + `${i.paragraphText === 'Wallet Address' && !state?.user?.address ? ' ' + module.placeholder : ''}`}
                    placeholder={i.placeholder}
                    copyTypeChangeAction={
                      i.copyOnMobile !== undefined && i.copyOnMobile
                    }
                  >
                    <UIButton blueButton={false} onClick={i.routingFunc}>
                      {i.buttonText}
                    </UIButton>
                  </UIMultiRow>
                ))}
              </UICard>
            </>
          ))}

        {routing !== "" && <UIPopupContainer>{checkRouting()}</UIPopupContainer>}
      </MainContainer>
    );
  } else {
    return(<>{navigate("/login")}</>)
  }
}

export default Settings;
