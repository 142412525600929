import { useContext, useCallback, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from 'axios'
import {
    UIActionCard,
    UIActionRowsCustomCard,
    UIButton,
    UISuccessButton,
} from "../../components/UI";

import { UserContext } from '../../utils/UserContext'
import module from "./style.module.scss";
import qs from 'qs';

const useQueryState = query => {
    const location = useLocation()
    const history = useNavigate()

    const setQuery = useCallback(
        value => {
        const existingQueries = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        })

        const queryString = qs.stringify(
            { ...existingQueries, [query]: value },
            { skipNulls: true }
        )

        history.push(`${location.pathname}?${queryString}`)
        },
        [history, location, query]
    )

    return [
        qs.parse(location.search, { ignoreQueryPrefix: true })[query],
        setQuery,
    ]
}

function Register() {
    const [card, setCard] = useState("password");
    // const [password, setPassword] = useState('');
    // const [passwordConfirmation, setPasswordConfirmation] = useState('');
    // const [email, setEmail] = useState('');
    const [token] = useQueryState('token');
    const { state, setState } = useContext(UserContext);
    const navigate = useNavigate();

    const cardInputItems = [{
        placeholder: "0x3f9...",
        registerName: "wallet",
        paragraphText: "Enter BEP20 Wallet Address",
        registerParams: {
            minLength: {
                message: "For ERC20 address type only. Please check the withdrawal address length and character content and try again. ",
                value: 35,
            },
        },
    }, ];

    const userFunc = () => {
        if (card === 'password') {
            let validationErrors = Array.from(document.getElementsByClassName('validation-error')).filter((element) => element.textContent !== '')
            let emailInput = document.getElementsByName('email')[0].value
            let passwordInput = document.getElementsByName('password')[0].value
            let passwordConfirmationInput = document.getElementsByName('passwordMatch')[0].value

            if (validationErrors.length === 0 && emailInput && passwordInput && passwordConfirmationInput) {
                axios.put(`${process.env.REACT_APP_API_HOST}/users/password`, { reset_password_token: token, user: { email: emailInput, reset_password_token: token, password: passwordInput, password_confirmation: passwordConfirmationInput } }, { withCredentials: true })
                    .then(response => {
                        if (response.data.user) {
                            setState(response.data);

                            return setCard("success");
                        } else {
                            let path = '/html/body/div/form/div[2]/div[1]';
                            let node = document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
                            node.insertAdjacentHTML('beforeend','<p class="style_card__error__Kmbc0  validation-error">Email not whitelisted</p>');

                            return setCard("password");
                        }
                    })
                    .catch((error) => {
                        console.log('api errors:', error)
                        return setCard('password');
                    })
            }
        }

        // if (card === 'password') {
        //     let validationErrors = Array.from(document.getElementsByClassName('validation-error')).filter((element) => element.textContent !== '')
        //     let emailInput = document.getElementsByName('email')[0].value
        //     let passwordInput = document.getElementsByName('password')[0].value
        //     let passwordConfirmationInput = document.getElementsByName('passwordMatch')[0].value

        //     if (validationErrors.length === 0 && emailInput && passwordInput && passwordConfirmationInput) {
        //       setEmail(emailInput)
        //         setPassword(passwordInput)
        //         setPasswordConfirmation(passwordConfirmationInput)
        //         setCard('wallet')
        //     }
        // } else if (card === 'wallet') {
        //     let walletInput = document.getElementsByName('wallet')[0].value

        //     axios.put(`${process.env.REACT_APP_API_HOST}/users/password`, { address: walletInput, reset_password_token: token, user: { reset_password_token: token, password: password, password_confirmation: passwordConfirmation } }, { withCredentials: true })
        //         .then(response => {
        //             if (response.data.user) {
        //                 setState(response.data);

        //                 return setCard("success");
        //             } else {
        //                 return setCard("password");
        //             }
        //         })
        //         .catch((error) => {
        //             console.log('api errors:', error)
        //             return setCard('password');
        //         })
        // }
    }

    return state?.logged_in ? (
        <>{ window.location.assign('/dashboard') }</>
    ) : card === "password" ? ( <
        UIActionCard cardType = "register"
        cardTitle = "Set The Password"
        userFunction = { userFunc }
        className = { module.register__card } >
        <
        UIButton > Confirm < /UIButton> < /
        UIActionCard >
    ) : card === "wallet" ? ( <
        UIActionRowsCustomCard size = ""
        title = "Wallet for SLEX Tokens"
        formSubmitFunction = { userFunc }
        className = { module.register__card } {... { cardInputItems } } >
        <
        UIButton > Confirm < /UIButton> < /
        UIActionRowsCustomCard >
    ) : ( <
        UISuccessButton title = "Account Created!"
        cardTitle = "Set The Password"
        buttonText = "Open Dashboard"
        className = { module.register__card }
        onClick = { window.location.assign('/dashboard') }
        />
    );
}

export default Register;