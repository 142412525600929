import {
  UICard,
  UIMultiRow,
  UIButton,
  UIPopupContainer,
  UISuccessButton,
} from "../../components/UI";

import MainContainer from "../../components/MainContainer";

import module from "./style.module.scss";

import successSvg from "./assets/success.svg";
import lockedSvg from "./assets/locked.svg";
import { useState, useContext, useEffect } from "react";
import { UserContext } from '../../utils/UserContext'
import { useNavigate } from "react-router-dom";
import axios from 'axios'

function Dashboard() {
  const [routing, setRouting] = useState("table");
  const splitNum = (i) => parseFloat(i).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  const routingCondition = (a, b, c, d) => {
    switch (routing) {
      case "table":
        return a;
      case "chequeInfo":
        return b;
      case "chequeSuccess":
        return c;
      case "success":
        return d;

      default:
        return d;
    }
  };
  const navigate = useNavigate();
  const { state } = useContext(UserContext);

  const dashboardAccountDetails = [
    {
      paragraphText: "Email",
      value: state?.user?.email,
      copyTypeChangeAction: false
    },
    {
      paragraphText: "Wallet Address",
      value: state?.user?.address || 'Address not Present',
      copyTypeChangeAction: true,
    },
  ];
  const [clear, setClear] = useState(true);
  const useAsyncVestignsData = () => {
    const [dashboardVesting, setDashboardVesting] = useState([]);
  
    useEffect(() => {
      const loadAsyncVestignsData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_HOST}/users/vesting`, { withCredentials: true })

          setDashboardVesting(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
          console.log(error)
        }
      };
  
      loadAsyncVestignsData();
    }, []);
  
    return { dashboardVesting, setDashboardVesting };
  };
  const { dashboardVesting, setDashboardVesting } = useAsyncVestignsData();
  const selectedVestings = dashboardVesting.filter((vesing) => vesing.checked);

  const unSelectAll = () => {
    setDashboardVesting(dashboardVesting.map(vesting => ({ ...vesting, checked: false })))
  }

  const updateCheckStatus = (index) => {
    setDashboardVesting(
      dashboardVesting.map((vesting, currentIndex) =>
        currentIndex === index
          ? { ...vesting, checked: !vesting.checked }
          : vesting
      )
    )
  }

  const DashboardTable = () => {
    return (
      <>
        <div className={module["dashboard-table"] + " " + module.desktop}>
          <div className={module.dashboard__row + " "}>
            <p>Type</p>
            <p>Unlock Date</p>
            <p>Amount</p>
            <p>Status</p>
            <p>Select</p>
          </div>
          {dashboardVesting.map((vesting, index) => (
            <>
              <div className={module.dashboard__row} key={vesting.id}>
                <p>{vesting.name}</p>
                <p>{new Date(vesting.unlock_date).toDateString()}</p>
                <p>{splitNum(vesting.amount)} SLEX</p>
                <p>{vesting.status}</p>
                <p>
                  {vesting.status.toLowerCase() === "completed" ? (
                    <img src={successSvg} alt="" />
                  ) : vesting.status.toLowerCase() === "locked" ? (
                    <img src={lockedSvg} alt="" />
                  ) : vesting.status.toLowerCase() === "processing" ? (
                      <img src={lockedSvg} alt="" />
                    ) : !clear ? (
                    <input
                      type="checkbox"
                      checked={vesting.checked}
                      className={module.dashboard__checkbox}
                      onChange={() => updateCheckStatus(index)}
                    />
                  ) : (
                    <input
                      type="checkbox"
                      checked={vesting.checked}
                      className={module.dashboard__checkbox}
                      onChange={() => updateCheckStatus(index)}
                    />
                  )}
                </p>
              </div>
            </>
          ))}
        </div>

        <div className={module["dashboard-table"] + " " + module.mobile}>
          {dashboardVesting.map((vesting, index) => (
            <>
              <div className={module.dashboard__row} key={vesting.id}>
                <p>
                  <span>{vesting.name}</span>
                  <span>
                    {vesting.status}
                    {vesting.status.toLowerCase() === "completed" ? (
                      <img src={successSvg} alt="" />
                    ) : vesting.status.toLowerCase() === "locked" ? (
                      <img src={lockedSvg} alt="" />
                    ) : vesting.status.toLowerCase() === "processing" ? (
                      <img src={lockedSvg} alt="" />
                    ) : !clear ? (
                      <input
                        type="checkbox"
                        checked={vesting.checked}
                        className={module.dashboard__checkbox}
                        onChange={() => updateCheckStatus(index)}
                      />
                    ) : (
                      <input
                        type="checkbox"
                        className={module.dashboard__checkbox}
                        checked={vesting.checked}
                        onChange={() => updateCheckStatus(index)}
                      />
                    )}
                  </span>
                </p>
              </div>

              <div className={module.dashboard__row} key={vesting.id}>
                <div>
                  <div>Amount:</div>
                  <div>Unlock Date:</div>
                </div>
                <div>
                  <div>{splitNum(vesting.amount)} SLEX</div>
                  <div>{new Date(vesting.unlock_date).toDateString()}</div>
                </div>
              </div>
            </>
          ))}
        </div>
      </>
    );
  };

  const DashboardWhiteBlock = ({ children }) => (
    <div className={module["dashboard-block_white"]}>{children}</div>
  );

  const DashboardCheque = () => {
    let numOr0 = n => isNaN(n) ? 0 : n
    const vestingsNames = selectedVestings.map((vesting) => vesting.name).join(', ')
    const vestingsSum = selectedVestings.map((vesting) => parseFloat(vesting.amount)).reduce((a, b) => numOr0(a) + numOr0(b), 0)
    const vestingInfo = [
      {
        amount: vestingsSum,
        address: state?.user?.address,
        vestings: vestingsNames,
      },
    ];

    return vestingInfo.map((i) => (
      <>
        <div>
          <p>Amount</p>
          <h4>{splitNum(i.amount)} SLEX</h4>
        </div>
        <div>
          <p>Recipient Address</p>
          <h4>{i.address}</h4>
        </div>
        <div>
          <p>Withdrawal Vestings</p>
          <h4>{i.vestings}</h4>
        </div>
      </>
    ));
  };

  const DashboardChequeSuccess = () => (
    <div className={module["dashboard-success"]}>
      <img src={successSvg} alt="" />
      <h4>Withrawal request confirmation sent to email.</h4>
    </div>
  );

  const DashboardButtons = ({ onClick }) => (
    <div>
      <UIButton {...{ onClick }} className={module["dashboard__button"]}>
        Withdraw
      </UIButton>
      <UIButton
        className={module["dashboard__button"]}
        blueButton={false}
        onClick={async () => {
          unSelectAll();
          setRouting("table");
          await setClear(false);
          await setClear(true);
        }}
      >
        Cancel
      </UIButton>
    </div>
  );

  if (state?.logged_in) {
    return (
      <MainContainer>
        <UICard blueHead={false} title="Account details" size="original">
          {dashboardAccountDetails.map((i, index) => (
            <>
              <UIMultiRow
                paragraphText={i.paragraphText}
                rowPlacing
                readOnly
                className={module.dashboard__input + " " + module.desktop + `${i.paragraphText === 'Wallet Address' && !state?.user?.address ? ' ' + module.placeholder : ''}`}
                value={i.value}
                copyTypeChangeAction={i.copyTypeChangeAction}
                key={index}
              />

              <UIMultiRow
                paragraphText={i.paragraphText}
                rowPlacing
                readOnly
                className={module.dashboard__input + " " + module.mobile + `${i.paragraphText === 'Wallet Address' && !state?.user?.address ? ' ' + module.placeholder : ''}`}
                value={
                  i.value.length >= 28 ? i.value.slice(0, 25) + "..." : i.value
                }
                copyValue={i.value}
                copyTypeChangeAction={i.copyTypeChangeAction}
                key={index}
              />
            </>
          ))}
        </UICard>

        <UICard
          blueHead={false}
          title="Vesting & Withdraw"
          size="original"
          contentClassName={module["dashboard-content"]}
        >
          {routingCondition(
            <DashboardTable />,
            <DashboardWhiteBlock>
              <DashboardCheque />
            </DashboardWhiteBlock>,
            <DashboardWhiteBlock>
              <DashboardChequeSuccess />
            </DashboardWhiteBlock>,
            <DashboardTable />
          )}

          {routing !== "chequeSuccess" ? (
            <DashboardButtons
              onClick={() => {
                if (routing === "chequeInfo") {
                  axios.post(`${process.env.REACT_APP_API_HOST}/users/withdraw`, { vestings_ids: selectedVestings.map((vesting) => vesting.id) }, { withCredentials: true })
                    .then(response => {
                      if (response.data) {
                        setRouting("chequeSuccess")
                      } else {
                        setRouting("table")
                      }
                    })
                    .catch((error) => {
                      setRouting("table")
                      console.log('api errors:', error)
                    })
                }
                if (selectedVestings.length > 0) {
                  setRouting("chequeInfo")
                }
              }}
            />
          ) : (
            <UIButton
              onClick={() => setRouting("success")}
              className={module["dashboard__button"]}
            >
              Done
            </UIButton>
          )}
        </UICard>

        {routing === "success" && (
          <UIPopupContainer>
            <UISuccessButton
              onSubmit={async () => {
                const response = await axios.get(`${process.env.REACT_APP_API_HOST}/users/vesting`, { withCredentials: true })

                setDashboardVesting(response.data);
                setRouting("table")
              }}
              title="Process Confirmed!"
              cardText="Thank you for confirmation. Your request will be processed in 24h."
            />
          </UIPopupContainer>
        )}
      </MainContainer>
    )
  } else {
    return(<>{navigate("/login")}</>)
  }
}

export default Dashboard;
