import { Routes, Route } from "react-router-dom";

import Register from "../../pages/Register";
import Login from "../../pages/Login";
import Dashboard from "../../pages/Dashboard";
import Settings from "../../pages/Settings";
import Help from "../../pages/Help";
import Info from "../../pages/Info";
import Buy from "../../pages/Buy";
import FAQ from "../../pages/FAQ";
import ResetPassword from "../../pages/ResetPassword";

function Router() {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/help" element={<Help />} />
      <Route path="/info" element={<Info />} />
      <Route path="/buy" element={<Buy />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/reset-password" element={<ResetPassword/>} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default Router;
