// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_login-card__xiSh8 {\n  transform: translateY(12.5%);\n}\n.style_login-link__iJ1ok {\n  color: #010481;\n  font-size: 14px;\n  font-weight: 500;\n  text-decoration: none;\n}", "",{"version":3,"sources":["webpack://./src/pages/Login/style.module.scss"],"names":[],"mappings":"AACI;EACI,4BAAA;AAAR;AAGI;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AADR","sourcesContent":[".login {\r\n    &-card {\r\n        transform: translateY(12.5%);\r\n    }\r\n\r\n    &-link{\r\n        color: #010481;\r\n        font-size: 14px;\r\n        font-weight: 500;\r\n        text-decoration: none;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-card": "style_login-card__xiSh8",
	"login-link": "style_login-link__iJ1ok"
};
export default ___CSS_LOADER_EXPORT___;
