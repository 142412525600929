import { useContext } from "react";
import { UserContext } from '../../utils/UserContext'

function Buy() {
  const { state } = useContext(UserContext);

  const url = () => {
    let link = 'https://slavi.io/staking/slex.html'

    if (state?.user?.email && state?.user?.address) {
      link = `${link}?email=${state?.user?.email}&address=${state?.user?.address}`
    } else if (state?.user?.email) {
      link = `${link}?email=${state?.user?.email}`
    } else if (state?.user?.address) {
      link = `${link}?address=${state?.user?.address}`
    }

    return(link);
  }

  window.open(
    url(),
    '_blank'
  )
  window.location.href = "/dashboard";

  return <></>;
}

export default Buy;
