// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_reset-card__7RMis {\n  transform: translateY(12.5%);\n}\n.style_reset-prefix__vtam\\+ {\n  font-weight: 500;\n  line-height: 140%;\n  color: #4A4A51;\n}\n.style_reset-error__Ng5Pt {\n  background-color: white;\n  border: 1px solid #4A4A4A;\n  padding: 22px 16px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n}\n.style_reset-error__Ng5Pt img {\n  margin-right: 16px;\n}", "",{"version":3,"sources":["webpack://./src/pages/ResetPassword/style.module.scss"],"names":[],"mappings":"AACI;EACI,4BAAA;AAAR;AAGI;EACI,gBAAA;EACA,iBAAA;EACA,cAAA;AADR;AAII;EACI,uBAAA;EACA,yBAAA;EAEA,kBAAA;EACA,WAAA;EAEA,aAAA;EACA,mBAAA;AAJR;AAMQ;EACI,kBAAA;AAJZ","sourcesContent":[".reset {\r\n    &-card {\r\n        transform: translateY(12.5%);\r\n    }\r\n\r\n    &-prefix{\r\n        font-weight: 500;\r\n        line-height: 140%;\r\n        color: #4A4A51;\r\n    }\r\n\r\n    &-error{\r\n        background-color: white;\r\n        border: 1px solid #4A4A4A;\r\n\r\n        padding: 22px 16px;\r\n        width: 100%;\r\n\r\n        display: flex;\r\n        align-items: center;\r\n\r\n        img{\r\n            margin-right: 16px;\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reset-card": "style_reset-card__7RMis",
	"reset-prefix": "style_reset-prefix__vtam+",
	"reset-error": "style_reset-error__Ng5Pt"
};
export default ___CSS_LOADER_EXPORT___;
