import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import useClipboard from "react-use-clipboard";

import module from "./style.module.scss";

import eyeSvg from "./assets/eye.svg";
import eyeSvgClosed from "./assets/eye-closed.svg";
import errorCrossSvg from "./assets/error-cross.svg";
import successPixelSvg from "./assets/success.svg";
import copySvg from "./assets/copy.svg";

const pasteEmptyPrefix = (i) => " " + i;

const checkClassNameCondition = (a, b, c, d) =>
  pasteEmptyPrefix(a === b ? c : d);

const checkClassNameDoubleCondition = (a, b, c, d, e, f, g) =>
  pasteEmptyPrefix(a === b ? c : d === e ? f : g);

const checkClassName = (className) =>
  checkClassNameCondition(className, "", "", pasteEmptyPrefix(className));

const staticErrors = [
  "A mixture of both uppercase and lowercase letters.",
  "A mixture of letters and numbers.",
  "Inclusion of at least one special character, e.g., ! @ # ? ].",
  "Do not use < or > in your password, as both can cause problems in Web browsers.",
];

// Дефолт карточка
export const UICard = ({
  size = "",
  title = "",
  blueHead = true,
  flexBetween = false,
  className = "",
  contentClassName = "",
  handleFormSubmit = () => {},
  centeredCard = true,
  children,
}) => {
  const checkSize = checkClassNameCondition(
    size,
    "original",
    module.card__block_original,
    module.card__block_small
  );

  const checkHead = checkClassNameCondition(
    blueHead,
    true,
    module["card__block-header_blue"],
    module["card__block-header_bare"]
  );

  const checkCentered = checkClassNameCondition(
    centeredCard,
    true,
    module.card__block_centered,
    ""
  );

  const userClassName = checkClassName(className);

  return (
    <form
      className={module.card__block + userClassName + checkSize + checkCentered}
      onSubmit={(e) => {
        e.preventDefault();
        handleFormSubmit();
      }}
    >
      <div className={module["card__block-header"] + checkHead}>{title}</div>
      <div
        className={
          module["card__block-content"] + pasteEmptyPrefix(contentClassName)
        }
      >
        {children}
      </div>
    </form>
  );
};

// Параграф
export const UIParagraph = ({ size = "small", className = "", children }) => {
  const checkDescription = checkClassNameDoubleCondition(
    size,
    "small",
    module.card__description_small,
    size,
    "medium",
    module.card__description_medium,
    module.card__description_large
  );

  const userClassName = checkClassName(className);

  return (
    <p className={module.card__description + userClassName + checkDescription}>
      {children}
    </p>
  );
};

// Инпут
export const UIInputCustomisable = ({
  widthFull = false,
  paddingType = "small",
  placeholder = "",
  type = "text",
  readOnly = false,
  required = false,
  value,
  copyValue = value,
  passwordTypeChangeAction = false,
  copyTypeChangeAction = false,
  textCopyAction = false,
  className = "",
  formHookProps = {},
}) => {
  const [passwordTypeState, setPasswordTypeState] = useState(false);
  const [isCopied, setCopied] = useClipboard(copyValue, {
    successDuration: 1000,
  });

  const checkLength = checkClassNameCondition(
    widthFull,
    true,
    module.card__input_full,
    ""
  );

  const checkPaddingType = checkClassNameCondition(
    paddingType,
    "small",
    module["card__input-padding_small"],
    module["card__input-padding_large"]
  );

  const userClassName = checkClassName(className);

  const InputButtonAction = (imageSource = "") => {
    return (
      <button
        className={module["card__input-button"]}
        onClick={(e) => {
          e.stopPropagation();

          if (passwordTypeChangeAction)
            return setPasswordTypeState(!passwordTypeState);
          else if (copyTypeChangeAction) return setCopied();
          return "";
        }}
      >
        <img src={imageSource.imageSource} alt="" />
      </button>
    );
  };

  const checkPasswordType = () => (passwordTypeState ? "text" : "password");

  return (
    <div className={module["card__input-wrapper"]}>
      <input
        className={
          module.card__input + userClassName + checkLength + checkPaddingType
        }
        type={passwordTypeChangeAction ? checkPasswordType() : type}
        {...{ placeholder, readOnly, required, value }}
        {...formHookProps}
      />

      {passwordTypeChangeAction && (
        <InputButtonAction
          imageSource={passwordTypeState ? eyeSvg : eyeSvgClosed}
        />
      )}

      {copyTypeChangeAction && <InputButtonAction imageSource={copySvg} />}

      {isCopied && (
        <div className={module["card__input-copy-notification"]}>
          Wallet address has been copied.
        </div>
      )}

      {copyTypeChangeAction}
    </div>
  );
};

// Кнопка
export const UIButton = ({
  blueButton = true,
  disabled = false,
  className = "",
  isLink = false,
  linkRoute = "/",
  onClick = () => {},
  children,
}) => {
  const userClassName = checkClassName(className);
  const checkBlueColor = checkClassNameCondition(
    blueButton,
    true,
    module.card__button_blue,
    module.card__button_bare
  );

  return isLink && !disabled ? (
    <Link
      className={module.card__button + userClassName + checkBlueColor}
      to={linkRoute}
    >
      {children}
    </Link>
  ) : (
    <button
      className={module.card__button + userClassName + checkBlueColor}
      {...{ onClick, disabled }}
    >
      {children}
    </button>
  );
};

// Текст ошибки
export const UIErrorDescription = ({
  error = "",
  errorCross = false,
  mapErrors = false,
}) => {
  const checkCrossSvg = checkClassNameCondition(
    errorCross,
    true,
    module["card__error_cross-included"],
    ""
  );

  return (
    <>
      <p className={module.card__error + checkCrossSvg + ' validation-error'}>
        {errorCross ? <img src={errorCrossSvg} alt={errorCrossSvg} /> : <></>}
        {error}
      </p>

      {mapErrors && (
        <div>
          {staticErrors.map((i, index) => (
            <UIErrorDescription error={i} className={"testststs"} errorCross={true} key={index} />
          ))}
        </div>
      )}
    </>
  );
};

// Инпут + пароль в однострочье
export const UIMultiRow = ({
  paragraphText = "",
  className = "",
  placeholder = "",
  type = "",
  readOnly = false,
  required = true,
  value,
  copyValue = value,
  passwordTypeChangeAction = false,
  copyTypeChangeAction = false,
  formHookProps = {},
  rowPlacing = false,
  paragraphSize = "small",
  children,
}) => {
  const userClassName = checkClassName(className);
  const rowPlacingClassName = checkClassNameCondition(
    rowPlacing,
    true,
    module["card__input-row_position"],
    ""
  );

  return (
    <div
      className={
        module["card__input-row"] + userClassName + rowPlacingClassName
      }
    >
      <UIParagraph size={paragraphSize} className={module["card__input-description"]}>{paragraphText}</UIParagraph>
      <UIInputCustomisable
        widthFull
        {...{
          placeholder,
          type,
          readOnly,
          passwordTypeChangeAction,
          copyTypeChangeAction,
          formHookProps,
          required,
          value,
          copyValue,
        }}
      />
      {children}
    </div>
  );
};

// Контент карточки почты
export const UIMultiEmailRow = ({
  className = "",
  required = true,
  passwordTypeChangeAction = false,

  formHookProps = {},
  defaultRegisterHookPropsIncluded = true,

  children,
}) => {
  const {
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const defaultRegisterHookProps = defaultRegisterHookPropsIncluded
    ? {
        required: "Email is required",
        pattern: {
          message: "Your email is not valid",
          value: /[a-z0-9]+@[a-z]+.+[a-z]{2,4}/,
        },
      }
    : {};

  return (
    <UIMultiRow
      paragraphText="Email"
      type="email"
      placeholder="Enter your email"
      formHookProps={register("email", {
        ...defaultRegisterHookProps,
        formHookProps,
      })}
      {...{
        className,
        required,
        passwordTypeChangeAction,
      }}
    >
      <UIErrorDescription error={errors?.email?.message} />
    </UIMultiRow>
  );
};

// Контент карточки пароля
export const UIMultiPasswordRow = ({
  className = "",
  required = true,

  minLengthPassword = 8,

  passwordMatch = true,
  passwordTypeChangeAction = true,
  readOnly = false,
  value,

  formHookProps = {},
  defaultRegisterHookPropsIncluded = true,
  paragraphPasswordText = "Password",
  registerName = "password",

  mapErrors = false,
  children,
}) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useForm({
    mode: "onChange",
  });

  const watchGetPasswordValue = watch("password");
  const watchGetPasswordMatchValue = watch("passwordMatch");

  const defaultRegisterHookProps = defaultRegisterHookPropsIncluded
    ? {
        required: "Password is required",
        minLength: {
          value: minLengthPassword,
          message: `At least ${minLengthPassword} characters.`,
        },
        pattern: {
          value:
            /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])^^[^<>]+$.*/,
          message: "Please, follow the instructions below",
        },
      }
    : {};

  return !passwordMatch ? (
    <UIMultiRow
      paragraphText="Password"
      type="password"
      required
      placeholder="Enter your password"
      passwordTypeChangeAction
      {...{ readOnly, value }}
      formHookProps={register(registerName, {
        ...defaultRegisterHookProps,
        ...formHookProps,
      })}
    >
      <UIErrorDescription
        // eslint-disable-next-line
        error={errors?.password?.message}
        {...{ mapErrors }}
      />
    </UIMultiRow>
  ) : (
    <>
      <UIMultiRow
        paragraphText={paragraphPasswordText}
        type="password"
        placeholder="Enter your password"
        passwordTypeChangeAction
        required
        {...{ readOnly, value }}
        formHookProps={register(registerName, {
          required: "Password is required",
          minLength: {
            value: minLengthPassword,
            message: `At least ${minLengthPassword} characters.`,
          },
          pattern: {
            value:
              /(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])^^[^<>]+$.*/,
            message: "Please, follow the instructions below",
          },
          ...formHookProps,
        })}
      >
        <UIErrorDescription
          // eslint-disable-next-line
          error={errors?.password?.message}
        />
      </UIMultiRow>

      <UIMultiRow
        paragraphText="Confirm Password"
        type="password"
        placeholder="Enter your password"
        required
        formHookProps={register("passwordMatch", {
          required: true,
        })}
        {...{ readOnly, passwordTypeChangeAction }}
      >
        {
          // eslint-disable-next-line
          errors?.password && (
            <div>
              {staticErrors.map((i, index) => (
                <UIErrorDescription error={i} errorCross={true} key={index} />
              ))}
            </div>
          )
        }

        {watchGetPasswordValue !== watchGetPasswordMatchValue ? (
          <UIErrorDescription
            error={"Passwords don't match"}
            errorCross={true}
          />
        ) : (
          ""
        )}
      </UIMultiRow>
    </>
  );
};

// шаблоны по рабочим карточкам
export const UIActionCard = ({
  cardTitle = "",
  userFunction = () => {},
  minLengthPassword = 8,
  cardType = "register",

  formHookProps = {},
  formHookPropsPassword = {},
  formHooks = true,

  className = "",
  children,
}) => {
  const { handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const checkCardType = (type) => {
    switch (type) {
      case "register":
        return (
          <>
            <UIMultiEmailRow {...{ formHookProps }} />
            <UIMultiPasswordRow {...{ formHookPropsPassword }} />
          </>
        );
      case "login":
        return (
          <>
            <UIMultiEmailRow
              {...{ formHookProps }}
              defaultRegisterHookPropsIncluded={false}
            />
            <UIMultiPasswordRow
              passwordMatch={false}
              defaultRegisterHookPropsIncluded={false}
              {...{ formHookPropsPassword }}
            />
          </>
        );
      case "email":
        return <UIMultiEmailRow {...{ formHookProps }} />;
      case "password":
        return <UIMultiPasswordRow {...{ formHookProps }} />;
      default:
        return <></>;
    }
  };

  const onSubmit = (data) => {
    if (data.password === data.passwordMatch) {
      userFunction(data);
      return reset();
    } else if (
      data.password === undefined &&
      data.passwordMatch === undefined
    ) {
      userFunction(data);
      return reset();
    } else {
      throw new Error("Something went wrong");
    }
  };

  return (
    <UICard
      title={cardTitle}
      handleFormSubmit={handleSubmit(onSubmit)}
      {...{ className }}
    >
      {checkCardType(cardType)}
      {children}
    </UICard>
  );
};

export const UISuccessButton = ({
  title = "",
  className = "",
  buttonText = "OK",
  cardText = "Data successfully changed",
  linkRoute = "/",
  isLink = false,
  onSubmit = () => {},
  onClick,
}) => {
  return (
    <UICard
      className={module.card__success + pasteEmptyPrefix(className)}
      size=""
      {...{ title }}
      handleFormSubmit={onSubmit}
    >
      <UIParagraph>
        <img src={successPixelSvg} alt="" />
        {cardText}
      </UIParagraph>
      <UIButton blueButton={false} {...{ linkRoute, isLink }} {...{ onClick }}>
        {buttonText}
      </UIButton>
    </UICard>
  );
};

// гибкая настраиваемая (кастомная) карточка
export const UIActionRowsCustomCard = ({
  size = "original",
  title = "",
  blueHead = true,
  flexBetween = false,
  className = "",
  contentClassName = "",
  formSubmitFunction = () => {},

  centeredCard = true,

  paragraphText = "",
  placeholder = "",
  type = "",
  readOnly = false,
  required = true,
  passwordTypeChangeAction = false,
  value = "",

  prefixContent = <></>,

  // Necessary!
  cardInputItems = [],
  // Necessary!

  typeFormMode = "onChange",

  children,
}) => {
  const {
    register,
    // eslint-disable-next-line
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: typeFormMode,
  });

  const functionUser = (data) => {
    formSubmitFunction(data);

    reset();
  };

  return (
    <UICard
      handleFormSubmit={handleSubmit(functionUser)}
      {...{
        size,
        title,
        blueHead,
        flexBetween,
        className,
        contentClassName,
        centeredCard,
      }}
    >
      {prefixContent}

      {cardInputItems.map((i, index) => (
        <UIMultiRow
          key={index}
          paragraphSize={i?.paragraphSize}
          placeholder={i?.placeholder}
          formHookProps={
            i.registerName && register(i.registerName, i.registerParams)
          }
          type={i?.type}
          readOnly={i?.readOnly}
          passwordTypeChangeAction={i?.passwordTypeChangeAction}
          paragraphText={i?.paragraphText}
          value={i?.value}
          copyTypeChangeAction={i?.copyTypeChangeAction}
          required={i?.required}
          copyValue={i?.copyValue}
        >
          <UIErrorDescription
            // eslint-disable-next-line
            error={eval("errors?." + i.registerName + "?.message")}
          />
        </UIMultiRow>
      ))}

      {children}
    </UICard>
  );
};

export const UIPopupContainer = ({ children }) => (
  <div className={module.card__popup}>{children}</div>
);
