import { useLocation } from "react-router-dom";

import module from "./style.module.scss";

import DashboardSvg from "./assets/dashboard.svg";
import FaqSvg from "./assets/faq.svg";
import HelpSvg from "./assets/help.svg";
import SettingsSvg from "./assets/settings.svg";

function MainContainer({ className = "", children }) {
  const location = useLocation().pathname;
  const checkLocation = () => {
    switch (location) {
      case "/dashboard":
        return DashboardSvg;
      case "/faq":
        return FaqSvg;
      case "/help":
        return HelpSvg;
      case "/info":
          return HelpSvg;
      case "/buy":
        return HelpSvg;
      case "/settings":
        return SettingsSvg;
      default:
        return DashboardSvg;
    }
  };

  return (
    <div className={module["container_main"]}>
      <div className={module["container-head"]}>
        <img src={checkLocation()} alt="" />
        <h4>{location.slice(1) === "faq" ? "FAQ" : location.slice(1)}</h4>
      </div>
      {children}
    </div>
  );
}

export default MainContainer;
