function Help() {
  window.open(
    'https://t.me/slex_private',
    '_blank'
  )
  window.location.href = "/dashboard";

  return <></>;
}

export default Help;
