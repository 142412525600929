import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { UICard, UIParagraph } from "../../components/UI";

import MainContainer from "../../components/MainContainer";

import module from "./style.module.scss";

import helpSvg from "./assets/help.svg";

function faq() {
  const accordionItems = [
    {
      title: "What is SLEX?",
      content: `SLEX - is a cryptocurrency that can be used to trade and pay fees on the SLEX cryptocurrency exchange.`,
    },
    {
      title: "What is Vesting?",
      content: `Vesting refers to the locking up/allocation of a certain percentage of the max circulation of the token before the initial coin offering (ICO) or initial DEX offering (IDO).`,
    },
    {
      title: "How many vestings does SLEX have?",
      content: `For Private Investors, SLEX tokens have only 24 vestings.`,
    },
    {
      title: "What is the return for the Investors?",
      content: `For Private and Seed Investors, we expect approximate return from 100% to 250% Compared To The Public Round. The Listing Price Is Not Included In These Calculations.`,
    },
    {
      title: "Where can I check the SLEX info?",
      content: `Follow to slex.io and check project info there.`,
    },
  ];

  return (
    <MainContainer>
      <UICard blueHead={false} title="Top FAQs" size="original" className={module.accordion} contentClassName={module["accordion-content"]}>
        <Accordion allowZeroExpanded>
          {accordionItems.map((i) => (
            <AccordionItem className={module.accordion__item}>
              <AccordionItemHeading className={module.accordion__head}>
                <AccordionItemButton>
                  <img src={helpSvg} alt="" />
                  {i.title}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={module.accordion__panel}>
                <UIParagraph size="large">{i.content}</UIParagraph>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </UICard>
    </MainContainer>
  );
}

export default faq;
