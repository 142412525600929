import { createContext, useState, useEffect } from "react";
import axios from 'axios'

const UserContext = createContext({
  state: { logged_in: false, user: {} },
  setState: () => {}
});

const UserContextProvider = ({ children }) => {
  const [state, setState] = useState(UserContext);
  const value = { state, setState };

  useEffect(() => {
    const fetchUser = () => {
      axios.get(`${process.env.REACT_APP_API_HOST}/logged_in`, { withCredentials: true })
        .then(response => {
          if (response.data.logged_in) {
            setState(response.data)
          } else {
            setState({ logged_in: false, user: {} })
          }
        })
        .catch((error) => {
          setState({ logged_in: false, user: {} });
          console.log('api errors:', error)
        })
    };

    fetchUser();
  }, []);


  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };