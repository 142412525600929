import { useContext, useState } from "react";
import { UserContext } from '../../utils/UserContext'
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios'

import {
  UIActionRowsCustomCard,
  UIButton,
  UIParagraph,
} from "../../components/UI";

import module from "./style.module.scss";
import errorSvg from "../ResetPassword/assets/error.svg";

const ResetError = () => (
  <div className={module["reset-error"]}>
    <img src={errorSvg} alt="" />
    <UIParagraph>
      Invalid Email or password
    </UIParagraph>
  </div>
);

function Login() {
  const [errorBlock, setErrorBlock] = useState(false);
  const { state, setState } = useContext(UserContext);

  const navigate = useNavigate();

  const cardInputItems = [
    {
      paragraphText: "Email",
      placeholder: "Your email",
      registerName: "EmailLogin",
      registerParams: {
        required: "Email is required",
        // validate: (v) => v === "email@mail.ru" || "Email is not found",
      },
    },
    {
      paragraphText: "Password",
      placeholder: "Your password",
      registerName: "PasswordLogin",
      registerParams: {
        required: "Password is required",
        // validate: (v) =>
          // invalidFlag ? false : `Incorrect password. You have more chances to input the correct password.`,
      },
      passwordTypeChangeAction: true,
    },
  ];

  // const errorFormHook = {};

  if (state?.logged_in) {
    return(<>{navigate("/dashboard")}</>)
  } else {
    return(
      <UIActionRowsCustomCard
        // typeFormMode="onChange"
        title="Login"
        size=""
        className={module["login-card"]}
        typeFormMode="onSubmit"
        formSubmitFunction={(event) => {
          axios.post(`${process.env.REACT_APP_API_HOST}/users/sign_in.json`, { user: { email: event.EmailLogin, password: event.PasswordLogin }}, { withCredentials: true })
          .then(response => {
            if (response.data.logged_in) {
              setState(response.data);
              setErrorBlock(false);

              navigate("/dashboard")
            } else {
              setErrorBlock(true)
            }
          })
          .catch((error) => {
            setErrorBlock(true);
            console.log('api errors:', error)
          })
        }}
        {...{ cardInputItems }}
        prefixContent={
          <>
            {errorBlock && <ResetError />}
          </>
        }
      >
        <UIButton>
          Login
        </UIButton>
        <Link to = "/reset-password" className={module["login-link"]}>Forgot your password?</Link>
        <Link to = "/register" className={module["login-link"]}>Register</Link>
      </UIActionRowsCustomCard>
    )
  }
}

export default Login;