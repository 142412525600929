import { useState } from "react";
import {
  UIActionRowsCustomCard,
  UIParagraph,
  UIButton,
  UISuccessButton,
} from "../../components/UI";

import module from "./style.module.scss";
import axios from 'axios'

import errorSvg from "./assets/error.svg";

function ResetPassword() {
  const [errorBlock, setErrorBlock] = useState(false);
  const [routing, setRouting] = useState("reset-password");
  const cardInputItems = [
    {
      placeholder: "Enter your email",
      paragraphText: "Type your email address",
      registerName: "EmailReset",
      registerParams: {},
    },
  ];

  const ResetError = () => (
    <div className={module["reset-error"]}>
      <img src={errorSvg} alt="" />
      <UIParagraph>
        There is no account registered to this email address.
      </UIParagraph>
    </div>
  );
  const ForgottenPassword = () => (
    <UIActionRowsCustomCard
      title="Forgot your password?"
      size=""
      className={module["reset-card"]}
      typeFormMode="onSubmit"
      formSubmitFunction={(event) => {
        axios.post(`${process.env.REACT_APP_API_HOST}/users/data/reset_password`, { user: { email: event.EmailReset }}, { withCredentials: true })
        .then(response => {
          if (response.data.success) {
            setErrorBlock(false);
            setRouting("success")
          } else {
            setErrorBlock(true);
          }
        })
        .catch((error) => {
          console.log('api errors:', error)
        })
      }}
      {...{ cardInputItems }}
      prefixContent={
        <>
          <UIParagraph className={module["reset-prefix"]}>
            Please enter the email address used to register. We will send your
            new password to that address.
          </UIParagraph>
          {errorBlock && <ResetError />}
        </>
      }
    >
      <UIButton>Retrieve password</UIButton>
    </UIActionRowsCustomCard>
  );
  const SuccessCard = () => (
    <UISuccessButton
      title="Password Changed"
      isLink
      linkRoute="/login"
      buttonText="Confirm"
      cardText="Your password has been successfully reset and a confirmation has been sent to your email address"
      className={module["reset-card"]}
    />
  );

  return routing === "reset-password" ? <ForgottenPassword /> : <SuccessCard />;
}

export default ResetPassword;
