// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../node_modules/react-scripts/node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!./fonts/Gilroy/stylesheet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  max-width: 1920px;\n  margin: auto;\n}\n\n@media (max-width: 1320px) {\n  #root {\n    flex-direction: column;\n  }\n}\n\n* {\n  font-family: \"Gilroy\", sans-serif;\n  outline: none;\n}", "",{"version":3,"sources":["webpack://./src/style.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,YAAA;AAAJ;;AAII;EADJ;IAEQ,sBAAA;EAAN;AACF;;AAGA;EACI,iCAAA;EACA,aAAA;AAAJ","sourcesContent":["@import \"./fonts/Gilroy/stylesheet.css\";\r\n\r\nbody{\r\n    max-width: 1920px;\r\n    margin: auto;\r\n}\r\n\r\n#root{\r\n    @media (max-width: 1320px) {\r\n        flex-direction: column;\r\n    }\r\n}\r\n\r\n* {\r\n    font-family: \"Gilroy\", sans-serif;\r\n    outline: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
