function Info() {
  window.open(
    'https://slex.gitbook.io/cryptocurrency-and-commodity-exchangeslex-slex',
    '_blank'
  )
  window.location.href = "/dashboard";

  return <></>;
}

export default Info;
